<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <!-- Sidenav / Navigation drawer -->
      <div>
        <!-- Topnav / App bar -->
        <v-main class="grey">
          <v-app-bar class="white" flat fixed>
            <v-toolbar-title>
              <div class="my-1 text-left">
                <img
                  src="@/assets/images/logo.png"
                  alt="Avatar"
                  class="img-yec-test"
                />
              </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn class="d-none d-md-block transparent" elevation="0" @click="Beranda">
              <v-icon class="me-2">ri-home-3-line</v-icon> Beranda
            </v-btn>
            <v-btn class="d-none d-md-block transparent" elevation="0" @click="Kelas">
              <v-icon class="me-2">ri-slideshow-line</v-icon> Kelas Saya
            </v-btn> -->
            <v-list dense flat class="d-none d-md-block">
              <v-list-item-group color="primary" style="display: flex">
                <template v-for="item in items">
                  <v-list-item
                    :disabled="
                      !user.is_updated_profile && item.title != 'Account'
                    "
                    :key="item.link"
                    class="py-1 pl-6"
                    active-class="menu-active"
                    :to="item.link"
                    @click="pageChanged(0, item)"
                  >
                    <v-list-item-icon class="mr-2 fs-14">
                      <v-icon
                        v-text="item.icon"
                        class="color--text"
                        :class="
                          user.is_updated_profile ||
                          item.title == 'Account' ||
                          'text--lighten-3'
                        "
                      ></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title
                        class="fs-14 color--text"
                        :class="
                          user.is_updated_profile ||
                          item.title == 'Account' ||
                          'text--lighten-3'
                        "
                        >{{ item.title }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
            <v-menu offset-y class="d-none d-md-block transparent">
              <template
                v-slot:activator="{ on, attrs }"
                class="d-none d-md-block transparent"
              >
                <v-btn
                  class="d-none d-md-block px-2 font-weight-regular"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!user.is_updated_profile"
                  elevation="0"
                  rounded
                  color="transparent"
                >
                <v-icon class="mr-3">ri-user-line</v-icon>
                  <span class="text-truncate d-inline-block" style="max-width: 80px;">{{ user.fullname }}</span>
                  <v-icon class="mx-2" small>$dropdown</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item link @click="Setting">
                  <v-list-item-title>
                    <v-icon>ri-user-settings-line</v-icon> Setting
                    Profile</v-list-item-title
                  >
                </v-list-item>
                <!-- <v-list-item link @click="Tele">
                  <v-list-item-title>
                    <v-icon>ri-briefcase-line</v-icon> Bimbingan
                    Karir</v-list-item-title
                  >
                </v-list-item> -->
                <!-- <v-list-item link @click="Sertifikat">
                  <v-list-item-title>
                    <v-icon>ri-file-list-2-line</v-icon>
                    Sertifikat</v-list-item-title
                  >
                </v-list-item> -->
                <v-list-item link @click="Logout">
                  <v-list-item-title> <v-icon>ri-logout-circle-line</v-icon> Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-app-bar-nav-icon
              v-if="!$vuetify.breakpoint.mdAndUp"
              @click="dialog.menuAuth = true"
            ></v-app-bar-nav-icon>
            <v-dialog
              v-model="dialog.menuAuth"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar>
                  <v-toolbar-title>
                    <div class="my-1 text-left">
                      <img
                        src="@/assets/images/logo.png"
                        alt="Avatar"
                        class="img-yec-test"
                      />
                    </div>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn
                      icon
                      dark
                      @click="dialog.menuAuth = false"
                      color="primary"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer></v-spacer>
                <v-card-text class="pa-5">
                  <v-list dense id="list-menu-user">
                    <!-- <v-list-item link @click="Beranda">
                      <v-list-item-title>
                        <v-icon>ri-home-3-line</v-icon>
                        Beranda</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link @click="Prakerja">
                      <v-list-item-title>
                        <v-icon>ri-community-line</v-icon> Kelas Prakerja
                        </v-list-item-title
                      >
                    </v-list-item> -->
                    <v-list-item link @click="Kelas">
                      <v-list-item-title>
                        <v-icon>ri-slideshow-line</v-icon> Kelas
                        Saya</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="">
                        <v-list-group :value="false" class="p-0">
                          <template v-slot:activator class="">
                            <v-list-item-content class="">
                              <v-list-item-title class="mb-2">
                                <v-icon>ri-user-line</v-icon>
                                {{ user.fullname }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>                      
                              <v-list-item link @click="Setting">
                                <v-list-item-title> <v-icon>ri-user-settings-line</v-icon> Setting Profile</v-list-item-title>
                              </v-list-item>
                              <!-- <v-list-item link @click="Tele">
                                <v-list-item-title> <v-icon>ri-briefcase-line</v-icon> Bimbingan Karir</v-list-item-title>
                              </v-list-item> -->
                              <!-- <v-list-item link @click="Sertifikat">
                                <v-list-item-title> <v-icon>ri-file-list-2-line</v-icon> Sertifikat</v-list-item-title>
                              </v-list-item> -->
                              <v-list-item link @click="Logout">
                                <v-list-item-title> <v-icon>ri-logout-circle-line</v-icon> Logout</v-list-item-title>
                              </v-list-item>
                              <v-list-item link @click="showScan=true;">
                                <v-list-item-title>
                                  <img src="@/assets/images/barcode.svg" width="18" class="mr-2"> Scan Absen</v-list-item-title
                                >
                              </v-list-item>
                          </v-list-group>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-app-bar>

          <v-container fluid style="margin-top: 64px">
            <transition name="fade-up">
              <router-view v-if="loaded"></router-view>
            </transition>
          </v-container>
          <div class="white">
            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <img
                    src="@/assets/images/logo.png"
                    alt="Avatar"
                    class="img-yec"
                  />
                  <div class="pe-5">
                    <p class="mb-0">
                      CV Kreasi Belajar Dinamis                    
                    </p>
                    <p>
                      © kreasibelajar 2020. All rights reserved.
                    </p>
                    
                    <!-- <p>
                      Dimana Lembaga Pelatihan YEC CO ID juga tergabung dalam program Kartu Prakerja dan berafiliasi dengan Digital Platform seperti <a href="https://www.karier.mu/">Karier.mu </a>, Tokopedia, Pintaria, dan Pijar Mahir.
                    </p> -->
                  </div>
                </div>
                <div class="col-md-4">
                  <h3 class="mb-2">Alamat</h3>
                  <div class="pe-5">
                    <p>
                       Jalan Kresna No A6, Gamping Kidul, Ambarketawang, Kec. Gamping, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55294
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <h3 class="mb-2">Customer Service</h3>
                  <!-- <span class="mb-2">
                    <a href="http://facebook.com/groups/prakerja.yec" 
                      target="_blank">
                      <img
                        src="@/assets/images/FB.png"
                        alt="Avatar"
                        class="mr-2"
                        style="width: 20px"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/yec.prakerja/"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/instagram 1.png"
                        alt="Avatar"
                        class="mr-2"
                        style="width: 20px"
                      />
                    </a>
                    <a
                      href="https://t.me/joinchat/S682QP2B7N2mp2Cq"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/telegram 1.png"
                        alt="Avatar"
                        class="mr-2"
                        style="width: 20px"
                      />
                    </a>
                    <a
                      href="https://chat.whatsapp.com/G3PMzDFbQLuHcOqK2mDERW "
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/whatsapp 1.png"
                        alt="Avatar"
                        class=""
                        style="width: 20px"
                      />
                    </a>
                  </span> -->
                  <p class="mb-0">Email : tryoutproofficial@gmail.com</p>
                    <p>Telepon interaktif / WA : 082325296203</p>
                </div>
              </div>
            </div>
          </div>
        </v-main>
      </div>
      <v-dialog
        v-model="showScan"
        persistent
      >
        <v-btn
          v-show="scanBarcode"
          @click="showScan = false"
          style="position: absolute;right: 0;top:0;"
          icon
        >
           <v-icon color="#000" large>ri-close-circle-line</v-icon>
        </v-btn>
        <v-card v-show="scanBarcode" class="pa-4">
          <v-card-text class="pa-0 text-center">
            <p class="text-h6 font-weight-normal">Scan Barcode untuk absen masuk kelas yang sudah dibeli</p>                      
            <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
          </v-card-text>
        </v-card>
        <v-card v-show="result" class="rounded-xl pa-4">
          <v-card-text class="pb-0 text-center">
            <span class="text-h5">Scan QR Code Absen Kelas</span>          
            <div class="col-md-8 mx-auto pt-0">            
              <p class="mb-0 text-body-1 pt-3">{{program}}</p>
            </div>
            <div v-if="res">
              <v-icon large color="success" class="mb-3">ri-checkbox-circle-fill</v-icon>
              <p color="success" class="text-body-1 font-weight-bold success--text">Berhasil</p>
            </div>
            <div v-else>
              <v-alert type="error" text v-show="response !== null">{{ response }}</v-alert>                                                    
              <v-icon large color="error" class="mb-3">ri-close-circle-line</v-icon>
            <p color="error" class="text-body-1 font-weight-bold error--text">Gagal</p>
            </div>
          </v-card-text>
        </v-card>        
      </v-dialog>
    </v-app>
  </transition>
</template>
<script>
import { mapGetters } from "vuex";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      drawer: null,
      notifications: [1],
      pageTitle: "",
      // title:false,
      pathnya: "",
      dialog: {
        menuAuth: false,
      },
      items: [
        // { title: "Beranda", icon: "$beranda", link: "/beranda" },
        // { title: "Kelas Prakerja", icon: "ri-community-line", link: "/prakerja" },
        { title: "Kelas Saya", icon: "$kelas", link: "/kelas" },
      ],
      showScan:false,
      scanBarcode:true,
      result:false,
      res:true,
      program:'',
      response:'',
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.$root.$on("setPageTitle", (pageTitle) => {
      this.pageTitle = pageTitle;
      // console.log(pageTitle);
    });

    // let path = "/" + this.$route.path.split("/")[1];
    this.pathnya = this.$route.path;
    // console.log("pathnya", this.pathnya);
    // let index = this._.findIndex(this.items, { link: path });
    // if (index != -1) {
    //   this.pageTitle = this.items[index].title;
    //   this.breadcrumbs.push({
    //     text: this.items[index].title,
    //     disabled: true,
    //     href: path,
    //   });
    // }
  },
  methods: {
    onDecode (result) { 
      if (result) {        
        const data={
          program_id:result,
        }
        this.axios
          .post("presence/v1/scan",data)
          .then(() => {
            this.getDetailClass(result);
            // console.log(response)
            this.result=true;
            this.scanBarcode=false;
             setTimeout(()=> {
              this.showScan=false;
              this.result=false;
              this.scanBarcode=true;
            }, 5000);
          })
          .catch((error) => {
            this.getDetailClass(result);
            // console.log(error.response)
            this.result=true;
            this.scanBarcode=false;
            this.res=false;
            this.response = error.response.data.message          
             setTimeout(()=> {
              this.result=false;
              this.scanBarcode=true;
              this.res=false;
              this.response=null;
            }, 5000);                   
          });
      }
    },
    Logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
      this.dialog.menuAuth = false;
    },
    Setting() {
      this.$router.push("/account");
      this.dialog.menuAuth = false;
    },
    Beranda() {
      this.$router.push("/beranda");
      this.dialog.menuAuth = false;
    },
    Kelas() {
      this.$router.push("/kelas");
      this.dialog.menuAuth = false;
    },
    Prakerja() {
      this.$router.push("/prakerja");
      this.dialog.menuAuth = false;
    },
    Sertifikat() {
      this.$router.push("/sertifikat");
            window.location.reload(true);
      
      this.dialog.menuAuth = false;
    },
    Tele() {
      window.open("https://t.me/joinchat/sI_iXQ5WSwA5MTY9", "_blank");
    },
    menu() {
      this.$router.push("/");
    },
    onLoaded() {
      console.log('Barcode reader loaded');
    },
    pageChanged(segment, location = null) {
      // console.log(segment);
      // console.log(location);
      this.pathnya = this.$route.path;
      // console.log("pathpage", this.pathnya);
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }
      // window.location.reload(true);
      document.title =
        this.breadcrumbs.map((val) => val.text).join(" -> ") +
        " - " +
        process.env.VUE_APP_TITLE;
    },
  },
};
</script>

<style>
.content-wrapper {
  position: relative;
}
/*.menu-active::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  left: 0;
  background: var(--v-primary-base);
}*/
/*.img-yec {
  height: 60px;
  width: 100%;
  object-fit: contain;
}*/
/*.img-yec:hover {
  cursor: pointer;
}*/
/*.img-yec-test {
  height: 60px;
  width: 100%;
  object-fit: revert;
}*/
#list-menu-user .v-list-item {
  padding: 8px 16px;
}
/*.img-yec-test:hover {
  cursor: pointer;
}*/
</style>